<section class="home-slider owl-carousel">
    
    <div class="slider-item" style="background-image:url(assets/images/banner.PNG);" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Now Available <span style="color: #ff8000;" >Medisoft version {{gs.dataSet.version}}</span></h1>
                    <h3 class="subheading" style="text-align: left;">CGM MEDISOFT V{{gs.dataSet.version}}</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3">Learn More</a>
                        <a href="javascript:void(0);" [routerLink]="['/new-feature']" class="btn btn-secondary px-4 py-3 mt-3 ml-2">New Feature</a>
                   
                    </p>
                </div>
            </div>
        </div>
    </div> 
    <!-- banner 01-->
     <div class="slider-item" style="background-image:url(assets/images/bg_1.jpg);" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Now Available <span>CGM Medisoft version {{gs.dataSet.version}}</span></h1>
                    <h3 class="subheading">Streamline your billing wtih CGM Medisoft V{{gs.dataSet.version}}</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3">Learn More</a>
                        <a href="javascript:void(0);" [routerLink]="['/mobile-patient-inkete']" 
                            class="btn btn-secondary px-4 py-3 mt-3 ml-2">Watch Video</a>
                    </p>
                </div>
            </div>
        </div>
    </div> 
    <!-- banner 02 -->
     <div class="slider-item" style="background-image:url(assets/images/bg_2.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Supercharge <span>Your Productivity</span></h1>
                    <h3 class="subheading">with CGM Medisoft Mobile.</h3>
                    <p><a href="javascript:void(0)" [routerLink]="['/prac-management']"
                            class="btn btn-secondary px-4 py-3 mt-3 ">Learn More</a>
                        <a href="javascript:void(0)" [routerLink]="['/prac-management']"
                            class="btn btn-secondary px-4 py-3 mt-3 ml-2">Watch Video</a></p>
                </div>
            </div>
        </div>
    </div> 
    <!-- banner 03 -->
     <div class="slider-item" style="background-image:url(assets/images/img/bn6.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Time is <span>Running Out!</span></h1>
                    <h3 class="subheading">Get discounts on CGM Medisoft V{{gs.dataSet.version}}</h3>
                    <p><a href="javascript:void(0)" [routerLink]="['/prac-management']"
                            class="btn btn-secondary px-4 py-3 mt-3">Learn More</a>
                        <a href="javascript:void(0)" [routerLink]="['/prac-management']"
                            class="btn btn-secondary px-4 py-3 mt-3 ml-2">Watch Video</a></p>
                </div>
            </div>
        </div>
    </div> 
</section>
<!-- cards -->
<section class="ftco-services ftco-no-pb">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-3 d-flex services align-self-stretch p-4 ftco-animate">
                <div class="media block-6 d-block text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                        <span class="flaticon-doctor"></span>
                    </div>
                    <div class="media-body p-2 mt-3">
                        <h3 class="heading">Qualified Professionals</h3>
                        <p>Medicomp for end users, a managed services offer, is designed to meet your key IT service
                            desk needs and leverages the strengths of industry-leading technologies to automate IT
                            operations</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex services align-self-stretch p-4 ftco-animate">
                <div class="media block-6 d-block text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                        <span class="flaticon-ambulance"></span>
                    </div>
                    <div class="media-body p-2 mt-3">
                        <h3 class="heading">Medical Billing Solution</h3>
                        <p>Medicomps committed to being a responsive partner and maximizing reimbursement by providing
                            solutions that allow you the freedom to focus on the core elements of your business</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex services align-self-stretch p-4 ftco-animate">
                <div class="media block-6 d-block text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                        <span class="flaticon-stethoscope"></span>
                    </div>
                    <div class="media-body p-2 mt-3">
                        <h3 class="heading">Consulting</h3>
                        <p>You will have a more efficient practice, and we will provide the tools to streamline your
                            work process, and increase the productivity of your office. This increase in productivity
                            and efficiency will yield higher revenues for your practice</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex services align-self-stretch p-4 ftco-animate">
                <div class="media block-6 d-block text-center">
                    <div class="icon d-flex justify-content-center align-items-center">
                        <span class="flaticon-24-hours"></span>
                    </div>
                    <div class="media-body p-2 mt-3">
                        <h3 class="heading">24 Hours Service</h3>
                        <p>We become an integrated part of your IT department providing the scale and skilled experts
                            you can trust. We will manage the day-to-day operations including end user support and
                            device management</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ABOUT MEDICOMP -->
<section class="ftco-section ftco-no-pt ftc-no-pb">
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-5 p-md-5 img img-2 mt-5 mt-md-0" style="background-image: url(assets/images/about27.jpg);">
            </div>
            <div class="col-md-7 wrap-about py-4 py-md-5 ftco-animate">
                <div class="heading-section mb-5">
                    <div class="pl-md-5 ml-md-5">
                        <span class="subheading">About medicomp</span>
                        <h2 class="mb-4" style="font-size: 24px;">At MediComp, your practice is our priority. Our
                            consultants will carefully analyze the needs of your practice and provide you with the best
                            solution</h2>
                    </div>
                </div>
                <div class="pl-md-5 ml-md-5 mb-5">
                    <div class="row mt-5 pt-2">
                        <div class="col-lg-6">
                            <div class="services-2 d-flex">
                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span
                                        class="flaticon-first-aid-kit"></span></div>
                                <div class="text">
                                    <h3>Security</h3>
                                    <p>Router Configuration, Switches Installation, VPN Setup.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="services-2 d-flex">
                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span
                                        class="flaticon-heart-rate"></span></div>
                                <div class="text">
                                    <h3>Support</h3>
                                    <p>Running Network Problem Tracing & Shifting Solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="services-2 d-flex">
                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span
                                        class="flaticon-dropper"></span></div>
                                <div class="text">
                                    <h3>Installation</h3>
                                    <p>Server Installation, Data Backup, Firewall Setup.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="services-2 d-flex">
                                <div class="icon mt-2 mr-3 d-flex justify-content-center align-items-center"><span
                                        class="flaticon-experiment-results"></span></div>
                                <div class="text">
                                    <h3>Networking</h3>
                                    <p>Structured Networking, Cable laying and planning.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Consultation label -->
<section class="ftco-intro" style="background-image: url(assets/images/bg_3.jpg);" data-stellar-background-ratio="0.5">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-9">
                <h2>We Provide Free Consultation</h2>
                <p class="mb-0">Your practice is our top priority. Please get in touch with us.</p>
                <p></p>
            </div>
            <div class="col-md-3 d-flex align-items-center">
                <p class="mb-0"><a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3">Free Consutation</a></p>
            </div>
        </div>
    </div>
</section>

<!-- Medisoft Clinical EHR -->
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate">
                <!-- <span class="subheading">Departments</span> -->
                <h2 class="mb-4">CGM Medisoft Clinical EHR</h2>
                <p>With the latest CGM Medisoft release, CGM offers CGM Medisoft Clinical, a complete electronic health
                    record/practice management system that works seamlessly to minimize practice disruption.</p>
            </div>
        </div>
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 tab-wrap">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel"
                            aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch"
                                        style="background-image: url(assets/images/pmsolutions.jpg);"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>A Complete EHR/PM Solution</h2>
                                    <p>With the latest CGM Medisoft release, CGM offers CGM Medisoft Clinical, a
                                        complete electronic health record/practice management system that works
                                        seamlessly to minimize practice sruption.With CGM Medisoft Clinical, you get the
                                        latest in practice automation without a lot of hassle and at a price you can
                                        afford.</p>
                                    <div class="row mt-5 pt-2">
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-first-aid-kit"></span></div>
                                                <div class="text">
                                                    <h3>Maximize Efficiency</h3>
                                                    <p>Unlike other EHRs, CGM Medisoft Clinical features Bright Note
                                                        Technology design that allows physicians to complete the
                                                        entire chart from a single progress note.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-dropper"></span></div>
                                                <div class="text">
                                                    <h3>Enhance Quality</h3>
                                                    <p>Web-based access to hundreds of disease and medication
                                                        protocols, A broad range of progress note templates covering
                                                        both primary care diagnosis and treatment</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-experiment-results"></span></div>
                                                <div class="text">
                                                    <h3>Ensure Safety</h3>
                                                    <p>Electronic prescribing (via SureScripts) improves patient
                                                        safety and speeds new prescription and refill workflow.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="services-2 d-flex">
                                                <div
                                                    class="icon mt-2 mr-3 d-flex justify-content-center align-items-center">
                                                    <span class="flaticon-heart-rate"></span></div>
                                                <div class="text">
                                                    <h3>Physician Tools</h3>
                                                    <p>Customize chart viewing by physician to allow provider (and
                                                        specialty) specific views for charts, chart summaries and
                                                        flow charts.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Qualified Professionals -->
<section class="ftco-section ftco-no-pt">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate">
                <span class="subheading">Professionals</span>
                <h2 class="mb-4">Our Qualified Professionals</h2>
                <p>At MediComp, your practice is our priority. Our consultants will carefully analyze the needs of your
                    practice and provide you with the best solution.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-3 ftco-animate">
                <div class="staff">
                    <div class="img-wrap d-flex align-items-stretch">
                        <div class="img align-self-stretch" style="background-image: url(assets/images/doc-1.jpg);">
                        </div>
                    </div>
                    <div class="text pt-3 text-center">
                        <h3>Dr. Lloyd Wilson</h3>
                        <span class="position mb-2">Neurologist</span>
                        <div class="faded">
                            <p>The healthcare industry is constantly changing, as well as the computer industry</p>
                            <ul class="ftco-social text-center">
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-twitter"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-facebook"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-google-plus"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 ftco-animate">
                <div class="staff">
                    <div class="img-wrap d-flex align-items-stretch">
                        <div class="img align-self-stretch" style="background-image: url(assets/images/doc-2.jpg);">
                        </div>
                    </div>
                    <div class="text pt-3 text-center">
                        <h3>Dr. Rachel Parker</h3>
                        <span class="position mb-2">Ophthalmologist</span>
                        <div class="faded">
                            <p>Our company provides practices with the tools that they need in order to increase
                                efficiency</p>
                            <ul class="ftco-social text-center">
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-twitter"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-facebook"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-google-plus"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 ftco-animate">
                <div class="staff">
                    <div class="img-wrap d-flex align-items-stretch">
                        <div class="img align-self-stretch" style="background-image: url(assets/images/doc-3.jpg);">
                        </div>
                    </div>
                    <div class="text pt-3 text-center">
                        <h3>Dr. Ian Smith</h3>
                        <span class="position mb-2">Dentist</span>
                        <div class="faded">
                            <p>increase time to dedicate to their practice We provide resources that management of a
                                practice easier.</p>
                            <ul class="ftco-social text-center">
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-twitter"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-facebook"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-google-plus"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-3 ftco-animate">
                <div class="staff">
                    <div class="img-wrap d-flex align-items-stretch">
                        <div class="img align-self-stretch" style="background-image: url(assets/images/doc-4.jpg);">
                        </div>
                    </div>
                    <div class="text pt-3 text-center">
                        <h3>Dr. Alicia Henderson</h3>
                        <span class="position mb-2">Pediatrician</span>
                        <div class="faded">
                            <p>These benefits will result in higher efficiency, higher profits for your practice, and
                                better patient care.</p>
                            <ul class="ftco-social text-center">
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-twitter"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-facebook"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-google-plus"></span></a></li>
                                <li class="ftco-animate"><a href="javascript:void(0);"><span class="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-12">
                <img src="assets/images/v27.jpg" width="100%">
            </div>
        </div>
    </div>
</section>

<!-- <section class="ftco-section testimony-section bg-light">
    <div class="container">
        <div class="row justify-content-center mb-5 pb-2">
            <div class="col-md-8 text-center heading-section ftco-animate">
                <h3 class="mb-4">Testimonials</h3>
                <p>Separated they live in. A small river named Duden flows by their place and supplies it with the
                    necessary regelialia. It is a paradisematic country</p>
            </div>
        </div>
        <div class="row ftco-animate justify-content-center">
            <div class="col-md-8">
                <div class="carousel-testimony owl-carousel">
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_1.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p>“Our practice signed on with Medicomp for our billing and IT support at the end
                                    of 2011 when our practice some growing pains. Their billing and IT staff are
                                    always attentive and very responsive with any concerns. They’ve even furthered
                                    our efforts to go paperless. Medicomp has been quintessential in growing our
                                    practice into one of the top Dermatology clinics in the greater DC area.”</p>
                                <p class="name">Michael R. Armstrong </p>
                                <span class="position">General Manager</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_2.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p>“I started working with Medicomp approximately 7 years ago. I was using another
                                    billing service and had enormous amounts of money from claims that had been
                                    rejected and had not been paid.I was desperate. Medicomp came to my rescue!They
                                    refilled old claims, set me up correctly with Medicare allowing me to
                                    collect.That was 7 years ago and I am still with them today.I am grateful to
                                    them.”</p>
                                <p class="name">Yolanda C. Holmes</p>
                                <span class="position"> M.D.</span>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="testimony-wrap d-flex">
                            <div class="user-img mr-4" style="background-image: url(assets/images/person_3.jpg)">
                            </div>
                            <div class="text ml-2 bg-light">
                                <span class="quote d-flex align-items-center justify-content-center">
                                    <i class="icon-quote-left"></i>
                                </span>
                                <p>“For several years, my practice has depended on Medicomp’s services and support
                                    including a full practice management and EMR solution. I can’t express enough
                                    how impressed we have been with Medicomp’s professionalism and dedication. Their
                                    billing department has saved us substantial time and money leaving us more time
                                    to concentrate on our patients. I highly recommend Medicomp.”</p>
                                <p class="name">Michael Rezaian</p>
                                <span class="position">M.D.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="ftco-section ftco-no-pt ftco-no-pb ftco-counter img" id="section-counter"
    style="background-image: url(assets/images/bg_3.jpg);" data-stellar-background-ratio="0.5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 py-5 pr-md-5">
                <div class="heading-section heading-section-white ftco-animate mb-5">
                    <span class="subheading">Consultation</span>
                    <h2 class="mb-4">Free Consultation</h2>
                    <p>Your practice is our top priority. Please get in touch with us.</p>
                </div>
                <form #f="ngForm" (ngSubmit)="onSubmit(f)" class="appointment-form ftco-animate">
                    <div class="d-md-flex">
                        <div class="form-group">
                            <input type="text" name="fname" ngModel class="form-control" placeholder="First Name">
                        </div>
                        <div class="form-group ml-md-4">
                            <input type="text" name="lname" ngModel class="form-control" placeholder="Last Name">
                        </div>
                    </div>
                    <div class="d-md-flex">
                        <div class="form-group">
                            <div class="form-field">
                                <div class="select-wrap">
                                    <div class="icon"><span class="ion-ios-arrow-down"></span></div>
                                    <select name="service" ngModel id="" class="form-control">
                                        <option value="">Select Your Services</option>
                                        <option value="neurology">Neurology</option>
                                        <option value="cardiology">Cardiology</option>
                                        <option value="dental">Dental</option>
                                        <option value="ophthalmology">Ophthalmology</option>
                                        <option value="other">Other Services</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group ml-md-4">
                            <input type="text" name="phone" ngModel class="form-control" placeholder="Phone">
                        </div>
                    </div>
                    <div class="d-md-flex">
                        <div class="form-group">
                            <div class="input-wrap">
                                <div class="icon"><span class="ion-md-calendar"></span></div>
                                <input type="text" name="date" autocomplete="off" ngModel id="appointment_date"
                                    class="form-control appointment_date" placeholder="Date">
                            </div>
                        </div>
                        <div class="form-group ml-md-4">
                            <div class="input-wrap">
                                <div class="icon"><span class="ion-ios-clock"></span></div>
                                <input type="text" name="time" ngModel id="appointment_time"
                                    class="form-control appointment_time" placeholder="Time">
                            </div>
                        </div>
                    </div>
                    <div class="d-md-flex">
                        <div class="form-group">
                            <textarea name="message" ngModel id="" cols="30" rows="2" class="form-control"
                                placeholder="Message"></textarea>
                        </div>
                        <div class="form-group ml-md-4">
                            <input type="submit" value="Appointment" class="btn btn-secondary py-3 px-4">
                        </div>
                    </div>
                    <div *ngIf="errors.err" class="danger">
                        <p>{{errors.msg}}</p>
                    </div>
                </form>
            </div>
            <div class="col-lg-6 p-5 bg-counter aside-stretch">
                <h3 class="vr">About MEDICOMP Facts</h3>
                <div class="row pt-4 mt-1">
                    <div class="col-md-6 d-flex justify-content-center counter-wrap ftco-animate">
                        <div class="block-18 p-5 bg-light">
                            <div class="text">
                                <strong class="number" data-number="30">0</strong>
                                <span>Years of Experienced</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center counter-wrap ftco-animate">
                        <div class="block-18 p-5 bg-light">
                            <div class="text">
                                <strong class="number">1 M+</strong>
                                <span>Happy Patients</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center counter-wrap ftco-animate">
                        <div class="block-18 p-5 bg-light">
                            <div class="text">
                                <strong class="number" data-number="84">0</strong>
                                <span>Number of Professionals</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center counter-wrap ftco-animate">
                        <div class="block-18 p-5 bg-light">
                            <div class="text">
                                <strong class="number" data-number="300">0</strong>
                                <span>Number of Staffs</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

