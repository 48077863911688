<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/mnt.png);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Medisoft & <span>Medisoft Clinical</span></h1>
                    <p class="subheading"><b>Call <span><a
                                    href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></span> to Reserve Your
                            Space</b></p>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Medicomp Supports All Version of
                            Medisoft</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/image_2.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">One to One<span>Workshop</span></h1>
                    <p class="subheading"><b>Call <span><a
                                    href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></span> to Reserve Your
                            Space</b></p>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ml-4">Click to Find More Information</a></p>

                </div>
            </div>
        </div>
    </div>

    <div class="slider-item" style="background-image:url(assets/images/P_mgnt2.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Medisoft v{{gs.dataSet.version}} <span>is now available!</span></h1>
                    <p class="subheading"><b>Call Us for a Free Quote!</b></p>
                    <p><a href="mobile-patient-inkete" class="btn btn-secondary px-4 py-3 mt-3 ml-4">Click to View Video</a></p>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item" style="background-image:url(assets/images/bg_1.jpg);" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">one to one workshop Informational Webinar<span>July 29th @ 3:00pm</span></h1>
                    <p class="subheading" style="text-align: justify;"><b>Below are links to additional resources
                            related to
                            one to one workshop practice will find helpful in preparing for the transition.</b></p>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3 ml-4">Click Here to Register and For More
                            Information</a></p>

                </div>
                <div class="row">
                    <div class="col-md-4 col-sm-4 col-xs-6"> <a href="#" class="btn btn-sm animated-button victoria-one"
                            style="color:#586769;"><b>Webinars eSolutions EDI</b></a> </div>
                    <div class="col-md-3 col-sm-3 col-xs-6"> <a href="#" class="btn btn-sm animated-button victoria-two"
                            style="color:#586769;"><b>Webinars WHO</b></a> </div>

                    <div class="col-md-5 col-sm-5 col-xs-6">
                        <a href="#" class="btn btn-sm animated-button victoria-four" style="color:#586769;"><b>ICD-10
                                Pysicians
                                Guide </b></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row mb-5 pb-2">
        <div class="col-md-10 heading-section ftco-animate fadeInUp ftco-animated">
            <div class="ftco-footer-widget mb-5 ml-md-4">
                <!-- <span class="subheading">Testimonials</span> -->
                <h4 class="mb-2 mt-4"><u>Medisoft Practice Management</u></h4>
                <div [innerHTML]="gs.dataSet.practice_management.practice">
                    <!-- <p style="text-align: justify;">Medisoft® version 27 — Simplify and streamline the way you run your
                        practice
                    </p>
                    <p style="text-align: justify;">Medisoft is a proven, trusted and affordable practice management
                        solution
                        designed to simplify and streamline the way you run your practice. medisoft V27 is the latest
                        release of a
                        system depended on by tens of thousands of physician practices. Brought to you by CGM,
                        Medisoft
                        v24
                        is an easy-to-use, fast solution for a modern day practice.</p>
                    <p style="text-align: justify;">Medisoft’s new Patient Intake Mobile application for iPad® and
                        Android™
                        automates the entire information-gathering process – giving you the ability to save valuable
                        time
                        and
                        money. Simply hand your patients the mobile device*, and through the Medisoft Mobile application
                        they can
                        enter their new information and edit already-existing information in Medisoft. The patient’s
                        information
                        will flow directly into Medisoft for you to validate and apply to their account.</p>
                    <p style="text-align: justify;">Medisoft’s Patient Intake Mobile application can significantly
                        reduce
                        the
                        amount of time spent copying patient information from intake forms into your software. With your
                        front
                        office staff focusing on more productive tasks, you have the ability to save thousands of
                        dollars by
                        increasing efficiency.</p>
                    <p style="text-align: justify;">*Mobile device not included with purchase of Medisoft PM or Medisoft
                        PM/EHR.
                    </p> -->
                </div>
                <!--  <p>Medisoft® version 27 — Simplify and streamline the way you run your practice</p>
            <p>Medisoft® version 27 — Simplify and streamline the way you run your practice</p> -->


                <h4 class="mb-2">Medisoft v{{gs.dataSet.version}} Benefits</h4>
                <div>
                    <ul class="list-unstyled" style="text-align: justify;">
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Provides a
                            seamless
                            path
                            to EHR adoption</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Improves
                            productivity of
                            the scheduling and billing staff</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Improves
                            productivity of
                            the scheduling and billing staff</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Speeds
                            insurance
                            billing
                            and improves reimbursement</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Improves
                            collections and
                            reduces accounts receivable</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Tracks practice
                            performance efficiently and automatically</li>
                        <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Reduces the
                            administrative burden on physicians so they can focus on patient care</li>
                    </ul>
                </div>


                <h4 class="mb-2 mt-3">ICD-10 Readiness and More</h4>
                <p style="text-align: justify;">The transition from ICD-9 to ICD-10 will significantly impact your
                    practice’s workflow, and Medisoft can help you manage the changes. With medisoft V27, you can take
                    advantage of several features that are designed to reduce manual entry and minimize the complexity
                    of
                    ICD-9 to ICD-10 mapping.</p>

                <!-- <h4 class="mb-2 mt-3">An Empowering Combination</h4>
                <p style="text-align: justify;">As of April 1, 2014, all physician practices and billers must use the
                    new
                    CMS 1500 Claim Form (02/12) which accommodates ICD-10 codes. medisoft V27 supports use of this form,
                    helping ensure accurate claims processing.</p> -->

                <h4 class="mb-2 mt-3" style="text-align: justify;">Improves office workflow and simplifies the patient
                    scheduling process</h4>
                <p style="text-align: justify;">Medisoft comes standard with a scheduling engine that replaces
                    inefficient
                    paper-based schedules with an electronic view of your office resources, making it quick and easy to
                    schedule, move or cancel appointments.</p>

                <h4 class="mb-2 mt-3">Streamlines accounting, billing and collection processes to make staff more
                    efficient
                    and help practices get paid faster</h4>

                <ul class="list-unstyled">
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Produce
                        professional,
                        easy to read patient statements.</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Produce a “quick
                        receipt”
                        for payments collected up-front.</li>

                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Verify insurance
                        eligibility before scheduled appointments.</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Collect co-payments
                        and
                        balances due at the time of check-in.</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Add payments
                        directly
                        into the Medisoft billing system from the scheduling screen.</li>

                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Automatically
                        populate
                        the Collections Worklist for follow-up with payors and patients on unpaid claims.</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Set up patients
                        using the
                        Patient Quick Entry feature, which consolidates billing data entry into one easy screen.</li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>From the office to
                        back
                        office, Medisoft helps make sure you receive the maximum reimbursement for the services you
                        provide.
                    </li>
                    <li><span class="ion-ios-arrow-round-forward mr-2" style="color:#2b8dcf;"></span>Submit claims
                        electronically and manage claims by resubmitting individual claims or batches of claims when
                        there is a
                        dispute with a payor.</li>


                </ul>
                <h4 class="mb-2 mt-3">Enhanced reporting – reporting has never been better</h4>
                <p style="text-align: justify;">medisoft V27 includes more than 200 reports to help make your practice
                    run
                    smoothly. Medisoft Reports give unparalleled information on managed care plans, in-depth financial
                    information, marketing statistics, service facility financial information, templates to export data
                    for
                    use in other software and the ability to run custom reports.</p>
                <p style="text-align: justify;">Medisoft Reports Professional (available as an add-on module) offers 368
                    reports, charts and graphs. It also includes a report server that enables reports to run at
                    scheduled
                    times and be delivered via e-mail, as well as the ability to edit standard reports and create custom
                    reports.</p>
                <h5 class="mb-2 mt-4" style="color:#ff8000;">Call Us Today: <span><a href="tel:{{gs.dataSet.phoneNo}}">{{gs.dataSet.phoneNo}}</a></span> to get started!</h5>
            </div>
        </div>
    </div>
</div>