import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContainerComponent } from './container/container.component';
import { HomeComponent } from './components/home/home.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { CleanicalEhrComponent } from './components/cleanical-ehr/cleanical-ehr.component';
import { PracManagementComponent } from './components/prac-management/prac-management.component';
import { ClimeStatementComponent } from './components/clime-statement/clime-statement.component';
import { CunsultingComponent } from './components/cunsulting/cunsulting.component';
import { TrainingComponent } from './components/training/training.component';
import { BillingSolComponent } from './components/billing-sol/billing-sol.component';
import { HardawerNetworkingComponent } from './components/hardawer-networking/hardawer-networking.component';
import { RemoteOnlineComponent } from './components/remote-online/remote-online.component';
import { CustumReportComponent } from './components/custum-report/custum-report.component';
import { SupportSolComponent } from './components/support-sol/support-sol.component';
import { DownloadLinkComponent } from './components/download-link/download-link.component';
import { EncoderProComponent } from './components/encoder-pro/encoder-pro.component';
import { V19DownloadComponent } from './components/v19-download/v19-download.component';
import { MobilePatientInketeComponent } from './components/mobile-patient-inkete/mobile-patient-inkete.component';
import { DragonMedicalComponent } from './components/dragon-medical/dragon-medical.component';
import { AppointMasterComponent } from './components/appoint-master/appoint-master.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { SpecialityEHRComponent } from './components/speciality-ehr/speciality-ehr.component';
import { UniversitieSchoolsComponent } from './components/universitie-schools/universitie-schools.component';
import { PracticeManagementComponent } from './components/practice-management/practice-management.component';
import { ElecronicHealthRecordComponent } from './components/elecronic-health-record/elecronic-health-record.component';
import { NewFeatureComponent } from './components/new-feature/new-feature.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    ContainerComponent,
    TestimonialsComponent,
    CleanicalEhrComponent,
    PracManagementComponent,
    ClimeStatementComponent,
    CunsultingComponent,
    TrainingComponent,
    BillingSolComponent,
    HardawerNetworkingComponent,
    RemoteOnlineComponent,
    CustumReportComponent,
    SupportSolComponent,
    DownloadLinkComponent,
    EncoderProComponent,
    V19DownloadComponent,
    MobilePatientInketeComponent,
    DragonMedicalComponent,
    AppointMasterComponent,
    AppointmentComponent,
    SpecialityEHRComponent,
    UniversitieSchoolsComponent,
    ElecronicHealthRecordComponent,
    PracticeManagementComponent,
    NewFeatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
