<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/505.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4" style="color: #000000">Version {{gs.dataSet.version}}<span style="color: #fff;">SP1 Hot Fix Web</span> Install</h1>
                    <h3 class="subheading">Version {{gs.dataSet.version}}, Sp1, SP1 Hot Fix Web Install Medisoft Version {{gs.dataSet.version}}</h3>
                    <p><a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
     <!-- <div class="slider-item" style="background-image:url(assets/images/img/506.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                  <h1 class="mb-4" style="color: #fafafa">Version {{gs.dataSet.version}}, Sp1<span>SP1 Hot Fix Web Install</span></h1>
                    <h3 class="subheading">Version {{gs.dataSet.version}}, Sp1, SP1 Hot Fix Web Install Medisoft Version {{gs.dataSet.version}}</h3>
                    <p><a href="#" class="btn btn-secondary px-4 py-3 mt-3">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div> -->
</section>


<div class="container">
    <div class="">
        <div class="col-md-12 col-sm-12 col-12" id="service_box">
            <img src="assets/images/v27.jpg" class="img-responsive"
                style="width: 100%;">
            <div class="body">
                <div class="row" style="padding: 10px;">
                    <div class="col-md-12 col-sm-12 col-12 pl-4">
                        <h6 style="padding-bottom: 20px;">Medisoft Version {{gs.dataSet.version}} and Version {{gs.dataSet.version}} SP1 Direct install
                            downloads</h6>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Version {{gs.dataSet.version}}</h6>
                                            <p style="color: #fafafa;">Medisoft Patient Accounting Version {{gs.dataSet.version}} Web
                                                Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>
                                            <a href="http://www.medisoft.com/Medisoft_Download/V19/GA/PAWebInstall.exe" class="w3-button w3-green">Download Medisoft v{{gs.dataSet.version}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Version {{gs.dataSet.version}} SP1</h6>
                                            <p style="color: #fafafa;">Medisoft Patient Accounting Version {{gs.dataSet.version}} SP1 Web
                                                Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>
                                            <a href="http://www.medisoft.com/Medisoft_Download/V19/SP1/WebInstall/PAWebInstall.exe" class="w3-button w3-green">Download Medisoft v{{gs.dataSet.version}} SP1</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="flip-box">
                                    <div class="flip-box-inner">
                                        <div class="flip-box-front">
                                            <h6 style="padding-top: 25px; font-size: 24px;color: #fafafa;">Medisoft
                                                Version {{gs.dataSet.version}} SP1 Hot Fix
                                            </h6>
                                            <p style="color: #fafafa;">Medisoft Patient Accounting Version {{gs.dataSet.version}} SP1 Hot
                                                Fix Web Install</p>
                                        </div>
                                        <div class="flip-box-back">
                                            <h2>Click Here</h2>

                                            <a href="http://www.medisoft.com/Medisoft_Download/V19/SP1HotFix/Medisoft19SP1Hotfix1.exe" class="w3-button w3-green">Download v{{gs.dataSet.version}} SP1 Hot Fix</a>
                                            <!-- <a href="#" class="btn btn-secondary" style="width: 70%;">Button</a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>