<section class="home-slider owl-carousel">
    <div class="slider-item" style="background-image:url(assets/images/img/bn7.jpg);">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start" data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">Faster, easier and<span>efficient Medical Billing</span></h1>
                    <h3 class="subheading">We make billing a comfortable experience for you and your patients</h3>
                    <p><a href="javascript:void(0);" class="btn btn-secondary px-4 py-3 mt-3 ">Get Started</a>
                        <a href="javascript:void(0);" [routerLink]="['/appointment']" class="btn btn-secondary px-4 py-3 mt-3 ml-2">Schedule a Demo</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="ftco-section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Medical Billing123</span>
                <h2 class="mb-4">Faster, easier and efficient Medical Billing</h2>
                <p>We make billing a comfortable experience for you and your patients</p>
            </div>
        </div>
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 nav-link-wrap">
                    <div class="nav nav-pills d-flex text-center" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link ftco-animate fadeInUp ftco-animated active" id="v-pills-1-tab" data-toggle="pill" href="#v-pills-1" role="tab" aria-controls="v-pills-1" aria-selected="false" style="width: 50%;">Medical Billing</a>
                        <a class="nav-link ftco-animate fadeInUp ftco-animated " id="v-pills-2-tab" data-toggle="pill" href="#v-pills-2" role="tab" aria-controls="v-pills-2" aria-selected="false" style="width: 50%;">Electronic Insurance</a>
                    </div>
                </div>
                <div class="col-md-12 tab-wrap">
                    <div class="tab-content bg-light p-4 p-md-5 ftco-animate fadeInUp ftco-animated" id="v-pills-tabContent">
                        <div class="tab-pane py-2 fade active show" id="v-pills-1" role="tabpanel" aria-labelledby="day-1-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/img/img10.jpg);"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>Medical Billing</h2>
                                    <p>Medical billing software efficiently handles all your day-to-day tasks and ensures up-to-date financial records. The multifunctional solution integrates with EHR and manages insurance claims, and payments. Furthermore, fasten insurance payouts by smooth sharing of patient information and data with insurance services without compromising confidentiality.</p>
                                    <div class="row pt-2">
                                        <p><strong>Right bill to the right patient</strong></p>
                                        <p>Our stringent services solves all your worries about billing by providing accurate statements to your patients.</p>
                                        <p>Submit insurance claims to multiple providers in an electronic format. Accelerate your revenue cycle by speeding up this process.</p>
                                        <!-- <p><strong>Electronic Insurance Submission</strong></p>
                                    <p>Submit insurance claims to multiple providers in an electronic format. Accelerate your revenue cycle by speeding up this process.</p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="v-pills-2" role="tabpanel" aria-labelledby="v-pills-day-2-tab">
                            <div class="row departments">
                                <div class="col-lg-4 order-lg-last d-flex align-items-stretch">
                                    <div class="img d-flex align-self-stretch" style="background-image: url(assets/images/pmsolutions.jpg);"></div>
                                </div>
                                <div class="col-lg-8">
                                    <h2>Electronic Insurance Submission</h2>
                                    <p>Submit insurance claims to multiple providers in an electronic format. Accelerate your revenue cycle by speeding up this process.
                                    </p>
                                    <div class="row pt-2">
                                        <ul class="list-unstyled">
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Manage claim denials</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Submit electronic claims</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Check insurance eligibility of your patients</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Fix denied claims accurately for resubmission</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Ensure that your insurance coverages are current</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Reduce delays in filling and denials due to late filing.</li>
                                            <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Search tool for ICD codes to prevent errors during input.</li>
                                        </ul>
                                    </div>
                                    <p><strong>Save costs and increase profitability</strong></p>
                                    <ul class="list-unstyled">
                                        <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Reduced errors</li>
                                        <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Automated coding</li>
                                        <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Transcription costs</li>
                                        <li style="color: #2f89fc;"><span class="ion-ios-arrow-round-forward mr-2"></span>Insurance claim denials</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center heading-section ftco-animate fadeInUp ftco-animated">
                <span class="subheading">Key Features</span>
                 <h2 class="mb-4">Faster, easier and efficient Medical Billing</h2>
                <p>Communicate with insurance companies without delay. Transmit patient details to insurance companies, speed up payments and reduce the paperwork. </p>
            </div>
        </div>
        <div class="row text-center align-items-stretch">
            <div class="my-4 col-md-4"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-plug"></i></div>
                            </div>
                            <h4 class="my-3">Integration</h4>
                            <p class="text-center">Integration provides consolidated patient data available for billing and practice management through an integrated EHR.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-4"><a>
                    <div class="card h-100 shadow"style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-hand-rock-o"></i></div>
                            </div>
                            <h4 class="my-3">Easy</h4>
                            <p class="text-center">The single point entry eliminates duplicate billing and errors in medical coding.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-4"><a>
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-file-text-o"></i></div>
                            </div>
                            <h4 class="my-3">Precision</h4>
                            <p class="text-center">Increase efficiency of your practice by identifying and correcting errors and generating accurate reports.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-4"><a href="/features/efficient">
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-thumbs-o-up"></i></div>
                            </div>
                            <h4 class="my-3">Efficient</h4>
                            <p class="text-center">Eliminate cumbersome data migration through integrated medical billing and EHR.</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-4"><a href="/features/speedy%20">
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-circle-o-notch"></i></div>
                            </div>
                            <h4 class="my-3">Speedy </h4>
                            <p class="text-center">Paperless documentation process that reduces the turnaround time for payouts</p>
                        </div>
                    </div>
                </a></div>
            <div class="my-4 col-md-4"><a href="/features/secure%20">
                    <div class="card h-100 shadow" style="border: 1px solid #ff8000;">
                        <div class="card-body">
                            <div class="text-center my-4">
                                <div><i class="fa fa-4x fa-lock"></i></div>
                            </div>
                            <h4 class="my-3">Secure </h4>
                            <p class="text-center">HIPAA compliant software that ensures the confidentiality of your patient data.</p>
                        </div>
                    </div>
                </a></div>
        </div>
    </div>
</section>