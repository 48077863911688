<section class="home-slider owl-carousel">
    <!-- <div class="slider-item" style="background-image:url(assets/images/marek-levak-GNVxujZ_CxU-unsplash.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <h1 class="mb-4">DEPARTMENTS <span>Medisoft Clinical EHR</span></h1>
                    <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical,
                        a
                        complete electronic health record/practice management system that works seamlessly to minimize
                        practice disruption.</p>
                </div>
            </div>
        </div>
    </div> -->

    <div class="slider-item" style="background-image:url(assets/images/stethoscope-3541909_960_720-960x480.jpg);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <!-- <h4 class="ftco-headi ng-2 logo" style="color: #2f89fc;">Discover Medisoft Mobile with
                        Patient Intake
                        and Streamline Your Office Efficiency</h4> -->
                    <h1 class="mb-4">Discover Medisoft Mobile <span style="font-size: 42px;">with
                            Patient Intake
                            and Streamline Your Office Efficiency</span></h1>
                    <!-- <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical, a
                        complete electronic health record/practice management system that works seamlessly to minimize
                        practice disruption.</p> -->

                </div>
            </div>
        </div>
    </div>
    <div class="slider-item" style="background-image:url(assets/images/Healthcare_Mobile_App_Development_Banner.webp);"
        data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
            <div class="row no-gutters slider-text align-items-center justify-content-start"
                data-scrollax-parent="true">
                <div class="col-md-6 text ftco-animate">
                    <!-- <h4 class="ftco-headi ng-2 logo" style="color: #2f89fc;">Discover Medisoft Mobile with
                        Patient Intake
                        and Streamline Your Office Efficiency</h4> -->
                    <h1 class="mb-4">Discover Medisoft Mobile <span style="font-size: 42px;">with
                            Patient Intake
                            and Streamline Your Office Efficiency</span></h1>
                    <!-- <p style="text-align: justify;">With the latest Medisoft release, CGM offers Medisoft Clinical, a
                        complete electronic health record/practice management system that works seamlessly to minimize
                        practice disruption.</p> -->

                </div>
            </div>
        </div>
    </div>
</section>
<section class="pt-2 pb-2">
    <div class="container">
        <div class="ftco-departments">
            <div class="row">
                <div class="col-md-12 tab-wrap">
                    <div class="tab-pane py-2 fade show active" id="v-pills-1" role="tabpanel"
                        aria-labelledby="day-1-tab">
                        <div class="row departments" style="background-color:rgb(250, 250, 250);">
                        </div>

                        <div class="col-lg-12" style="text-align: justify;">
                            <!-- <h4 class="ftco-headi ng-2 logo" style="color: #2f89fc;">Discover Medisoft Mobile with
                                Patient Intake
                                and Streamline Your Office Efficiency</h4> -->
                            <h5><strong>Available exclusively with Medisoft v{{gs.dataSet.version}}</strong></h5>
                            <p>Medisoft Mobile with Patient Intake, a free app for iPad®, IPhone® and Android™ devices,
                                combined
                                with medisoft V27, the latest upgrade to the Medisoft practice management system, will
                                help your
                                practice run more efficiently by optimizing reimbursement and improving productivity
                                freeing up staff
                                to address more important tasks.</p>
                            <p>The Patient Intake component of the app automates the entire information-gathering
                                process reducing
                                the amount of time spent copying patient information from intake forms into your
                                software. Simply hand
                                your patients the tablet device, and through the Medisoft Mobile application they can
                                enter their new
                                information and edit already-existing information in Medisoft. The patient’s information
                                will flow
                                directly into Medisoft for you to validate and apply to their account.</p>

                            <h5><u>Together Medisoft v{{gs.dataSet.version}} and Medisoft Mobile allow you to perform the following:</u>
                            </h5>
                            <div class="row mb-4">
                                <div class="col-lg-6 col-md-6">
                                    <ul class="list-unstyled">
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Electronically
                                            capture charges and diagnoses (no more paper superbills!).</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Enter
                                            charges and
                                            diagnoses from anywhere.</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Submit
                                            claims to your
                                            biller at the tap of a button.</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>View
                                            your schedule.
                                        </li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Track
                                            practice
                                            performance efficiently and easily.</li>
                                    </ul>
                                </div>
                                <div class="embed-responsive embed-responsive-16by9 col-lg-6 col-md-6"
                                    style="width: 100%; border: 1px solid #2b8dcf;">
                                    <iframe class="embed-responsive-item"
                                        src="https://www.youtube.com/embed/ZoitDKOcLSk" allowfullscreen></iframe>
                                </div>
                            </div>
                            <!-- <br> -->

                            <h5><u>Medisoft Mobile with Patient Intake will allow your patients (via tablet device) to
                                    quickly add
                                    new, or verify existing, patient information including:</u></h5>
                            <div class="row mb-4">
                                <div class="col-lg-6 col-md-6">
                                    <ul class="list-unstyled">
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Demographics</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Employment</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Payment
                                            responsibility</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Insurance</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Emergency contact
                                        </li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Medical
                                            history</li>
                                        <li><span class="ion-ios-arrow-round-forward mr-2"
                                                style="color:#2b8dcf;"></span>Consent
                                            to any office
                                            form</li>
                                    </ul>
                                </div>

                                <div class="embed-responsive embed-responsive-16by9col-lg-6 col-md-6"
                                    style="width: 100%; border: 1px solid #2b8dcf;">
                                    <iframe class="embed-responsive-item"
                                        src="https://www.youtube.com/embed/hQhxIDvGYTo" allowfullscreen></iframe>
                                </div>
                            </div>
                            <!-- <br> -->
                            <h5><u>Medisoft Mobile Screen Shots</u></h5>
                            <!-- <div class="row mb-2">
                                <div class="col-md-6">
                                    <img src="assets/images/01.png" alt="" style="width: 100%;">
                                </div>
                                <div class="col-md-6">
                                    <img src="assets/images/02.png" alt="" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-6">
                                    <img src="assets/images/03.png" alt="" style="width: 100%;">
                                </div>
                                <div class="col-md-6">
                                    <img src="assets/images/04.png" alt="" style="width: 100%;">
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-6">
                                    <img src="assets/images/05.png" alt="" style="width: 100%;">
                                </div>
                            </div> -->

                            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                                
                                <div class="carousel-inner" style="overflow: hidden;margin: auto !important;width: 75%;">
                                    <div class="carousel-item active">
                                        <img class="d-block w-100" src="assets/images/01.png" alt="First slide">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100" src="assets/images/02.png" alt="Second slide">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100" src="assets/images/03.png" alt="Third slide">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100" src="assets/images/04.png" alt="Third slide">
                                    </div>
                                    <div class="carousel-item">
                                        <img class="d-block w-100" src="assets/images/05.png" alt="Third slide">
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                    data-slide="prev">
                                    <i class="fa fa-chevron-left"></i>
                                    <!-- <span class="carousel-control-prev-icon" aria-hidden="true"></span> -->
                                    <button class="btn btn-primary">Prev</button>
                                    <span class="sr-only">Previous</span>
                                </a>
                                <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                    data-slide="next">
                                    <!-- <span class="carousel-control-next-icon btn btn-primary" aria-hidden="true">Next</span> -->
                                    <button class="btn btn-primary">Next</button>
                                    <span class="sr-only">Next</span>
                                </a>
                            </div>
                            <!-- <ul style=" display:table-row; ">

                                <li style="display:table-cell;"><img 
                                        src="assets/images/01.png" />
                                </li>
                                <li style="display:table-cell;"><img 
                                        src="assets/images/02.png" />
                                </li>
                                <li style="display:table-cell;"><img 
                                        src="assets/images/03.png" />
                                </li>
                                <li style="display:table-cell;"><img 
                                        src="assets/images/04.png" />
                                </li>
                                <li style="display:table-cell;"><img 
                                        src="assets/images/05.png" />
                                </li>

                            </ul> -->
                            <!-- <section class="home-slider owl-carousel">
                                <div class="slider-item" style="background-image:url(assets/images/01.png);"
                                    data-stellar-background-ratio="0.5">
                                    <div class="overlay"></div>
                                    <div class="container">
                                        <div class="row no-gutters slider-text align-items-center justify-content-start"
                                            data-scrollax-parent="true">
                                            <div class="col-md-6 text ftco-animate">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="slider-item" style="background-image:url(assets/images/02.png);">
                                    <div class="overlay"></div>
                                    <div class="container">
                                        <div class="row no-gutters slider-text align-items-center justify-content-start"
                                            data-scrollax-parent="true">
                                            <div class="col-md-6 text ftco-animate">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="slider-item" style="background-image:url(assets/images/03.png);"
                                    data-stellar-background-ratio="0.5">
                                    <div class="overlay"></div>
                                    <div class="container">
                                        <div class="row no-gutters slider-text align-items-center justify-content-start"
                                            data-scrollax-parent="true">
                                            <div class="col-md-6 text ftco-animate">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="slider-item" style="background-image:url(assets/images/04.png);">
                                    <div class="overlay"></div>
                                    <div class="container">
                                        <div class="row no-gutters slider-text align-items-center justify-content-start"
                                            data-scrollax-parent="true">
                                            <div class="col-md-6 text ftco-animate">
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="slider-item" style="background-image:url(assets/images/05.png);">
                                    <div class="overlay"></div>
                                    <div class="container">
                                        <div class="row no-gutters slider-text align-items-center justify-content-start"
                                            data-scrollax-parent="true">
                                            <div class="col-md-6 text ftco-animate">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</section>